import React from "react";
import {Link} from "gatsby";
import {IPortfolio} from "../../models/portfolio";
import Img from "gatsby-image";
import ExitLabel from "./exit-label";
import InactiveLabel from "./inactive-label";

const FilteredPortfolioMobile: React.FC<{
  filteredPortfolio: IPortfolio[];
  className?: string;
}> = ({filteredPortfolio, className = ""}) => {
  return (
    <div className={`flex flex-col md:hidden ${className}`}>
      {filteredPortfolio.map((portfolio) => (
        <Link
          key={`mobile-${portfolio.slug}`}
          to={`/portfolio/${portfolio.slug}`}
        >
          <div className="w-full h-18 flex p-5 mb-2   border-2 border-lightGray items-center ">
            <img
              src={portfolio.logo.publicURL}
              alt={portfolio.name}
              className="w-14 mr-4 object-contain"
            />

            {/* <Img
              fluid={{
                ...portfolio.logo.childImageSharp.fluid,
                aspectRatio: 1,
              }}
              alt={portfolio.name}
              className="w-8 h-8 mr-4"
            /> */}
            <span className="font-bold mr-2">{portfolio.name}</span>
            {portfolio.status === "Exit" ? (
              <ExitLabel className="h-5 px-2 text-xs " />
            ) : portfolio.status === "Inactive" ? (
              <InactiveLabel className="h-5 px-2 text-xs" />
            ) : null}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default FilteredPortfolioMobile;
