import React, {ReactNode, ButtonHTMLAttributes} from "react";

function Button({
  children,
  secondary = false,
  className = "",
  fontSize = "base",
  bolder = false,
  autoWidth = false,
  small = false,
  ...others
}: {
  children: ReactNode;
  secondary?: boolean;
  className?: string;
  fontSize?: "base" | "sm";
  bolder?: boolean;
  autoWidth?: boolean;
  small?: boolean;
} & ButtonHTMLAttributes<{}>) {
  return (
    <button
      className={`${autoWidth ? "" : "w-29"} ${
        small ? `h-6` : `h-11`
      }  flex justify-center items-center border-2 border-solid  ${
        secondary
          ? "text-white bg-primary border-white"
          : "text-primary  border-primary"
      } ${fontSize === "sm" ? `text-sm` : ``} ${
        bolder ? `font-bold` : ``
      } ${className}`}
      {...others}
    >
      {children}
    </button>
  );
}
export default Button;
