import React, { useState, useEffect } from "react";
import FilterItemsList from "./filter-items-list";
import { IFilterItem } from "../models/common";
const DropDown = ({
  items,
  onApplySelection,
  onClose,
}: {
  items: IFilterItem[];
  onApplySelection: (items: string[]) => void;
  onClose?: () => void;
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  useEffect(() => {
    const tmpSelectedItems = items
      .filter((item) => item.isSelected)
      .map((item) => item.value);
    setSelectedItems(tmpSelectedItems);
  }, [items]);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: IFilterItem
  ) => {
    const index = selectedItems.findIndex((itm) => itm === item.value);
    if (index >= 0) {
      const tmpSelectedItems = [...selectedItems];
      tmpSelectedItems.splice(index, 1);
      setSelectedItems(tmpSelectedItems);
    } else {
      setSelectedItems([...selectedItems, item.value]);
    }
  };
  return (
    <div className="hidden md:block">
      <div
        className="fixed inset-0  z-40 cursor-default"
        onClick={() => onClose()}
      ></div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="absolute top-12 left-0 right-0 bottom-auto shadow-filter border-1 border-solid border-dropDownBorder 
        text-secondary pb-4 px-4 bg-white z-50 cursor-default"
      >
        <FilterItemsList
          items={items}
          selectedItems={selectedItems}
          onChange={onChange}
        />

        <div className="grid grid-cols-2 col-gap-8">
          <button
            className="h-8 border-2 border-solid border-primary text-primary"
            onClick={() => setSelectedItems([])}
          >
            {" "}
            Clear{" "}
          </button>
          <button
            className="h-8 bg-primary text-white"
            onClick={() => onApplySelection(selectedItems)}
          >
            {" "}
            Apply{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DropDown;
