import React from "react";

const ExitLabel: React.FC<{ className?: string }> = ({ className = "" }) => (
  <div
    className={`font-bold md:text-xs lg:text-base bg-primary text-white flex items-center ${className}`}
  >
    EXIT
  </div>
);
export default ExitLabel;
