import React from "react";
import {Link} from "gatsby";
import {IPortfolio} from "../../models/portfolio";
import Img from "gatsby-image";
import ExitLabel from "./exit-label";
import InactiveLabel from "./inactive-label";

const FilteredPortfolioDesktop: React.FC<{
  filteredPortfolio: IPortfolio[];
  className?: string;
}> = ({filteredPortfolio, className = ""}) => {
  return (
    <div className="w-full flex justify-center">
      <div
        style={{gridTemplateColumns: "repeat(4, 18vw)", gridGap: "2vw"}}
        className={`hidden md:grid ${className}`}
      >
        {filteredPortfolio.map((portfolio) => (
          <div
            key={`desktop-${portfolio.slug}`}
            style={{height: "18vw"}}
            className="portfolio-box relative border-2 border-lightGray flex justify-center items-center "
          >
            <img
              src={portfolio.logo.publicURL}
              alt={portfolio.name}
              className="p-4 object-contain"
            />
            {/* <Img
              fluid={{
                ...portfolio.logo.childImageSharp.fluid,
                aspectRatio: 1,
              }}
              alt={portfolio.name}
              className="w-20 h-20"
            /> */}
            {portfolio.status === "Exit" ? (
              <ExitLabel className="absolute right-2 top-2 px-2 " />
            ) : portfolio.status === "Inactive" ? (
              <InactiveLabel className="absolute right-2 top-2 px-2 " />
            ) : null}

            <Link
              to={`/portfolio/${portfolio.slug}`}
              className="portfolio-details hidden absolute inset-0 bg-primary text-white"
            >
              <div className="p-6 h-full w-full flex flex-col justify-between ">
                <span className="text-xl font-bold"> {portfolio.name}</span>
                <div className="flex flex-col text-sm">
                  <span>{portfolio.sector ? portfolio.sector.name : ""}</span>
                  <span>{portfolio.fundingStage}</span>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilteredPortfolioDesktop;
