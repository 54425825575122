import React from "react";
import "./checkbox.css";
const Checkbox = ({
  key = "",
  id = "",
  name = "",
  text = "",
  isChecked = false,
  onChange,
  bordered = false,
  ...others
}) => {
  return (
    <label className="container" key={key} htmlFor={id}>
      {text}
      <input
        type="checkbox"
        id={id}
        //value={isChecked}
        checked={isChecked}
        onChange={() => {
          onChange();
        }}
        name={name}
        {...others}
      />
      <span
        className={`checkmark ${bordered ? "checkmark--bordered" : ""}`}
      ></span>
    </label>
  );
};
export default Checkbox;
