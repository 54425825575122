import React from "react";
import {IFilterItem} from "../models/common";
import Checkbox from "./checkbox/checkbox";

const FilterItemsList: React.FC<{
  items: IFilterItem[];
  selectedItems: string[];
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    item: IFilterItem
  ) => void;
}> = ({items, selectedItems, onChange}) => {
  const randomString = Math.random().toString(36).substring(7);
  return (
    <div className="pt-3">
      {items.map((item, index) => (
        <div key={index}>
        <Checkbox
          // key={item.value}
          text={item.value}
          id={`${randomString}-${index}`}
          isChecked={selectedItems.includes(item.value)}
          onChange={(e) => onChange && onChange(e, item)}
        />
        </div>
      ))}
    </div>
    // <ul className="flex flex-col py-4">
    //   {items.map((item, index) => (
    //     <li className="py-2" key={item.value}>
    //       <input
    //         type="checkbox"
    //         id={`${randomString}-${index}`}
    //         className="mr-2 "
    //         checked={selectedItems.includes(item.value)}
    //         onChange={(e) => onChange && onChange(e, item)}
    //       />
    //       <label
    //         htmlFor={`${randomString}-${index}`}
    //         className="cursor-pointer"
    //       >
    //         {item.value}
    //       </label>
    //     </li>
    //   ))}
    // </ul>
  );
};

export default FilterItemsList;
