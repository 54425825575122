import {IFilterItem} from "../models/common";

function isUniqueFilterItems(
  currentItem: IFilterItem,
  index: number,
  allItems: IFilterItem[]
) {
  const foundIndex = allItems.findIndex(
    (item) => item.value && item.value === currentItem.value
  );
  return foundIndex === index;
}

export default isUniqueFilterItems;
