import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import {
  IPortfolio,
  IFilterType,
  IAllItems,
  ISelectedItems,
} from "../models/portfolio";
import { IFilterItem } from "../models/common";
import Box from "../components/portfolio/filter-box";
import FilteredPortfolioDesktop from "../components/portfolio/filtered-portfolio-desktop";
import FilteredPortfolioMobile from "../components/portfolio/filtered-portfolio-mobile";
import isUniqueFilterItems from "../utils/getUniqueFilterItems";

// type filterToggleObjType = { [key: FiltersStringsType]: boolean };
const offFilters: IFilterType = {
  commonFilter: false,
  sectors: false,
  stages: false,
  statuses: false,
  models: false,
};

const PortfolioPage = ({ data }) => {
  const portfolios = data.allStrapiStartups.nodes as Array<IPortfolio>;
  const founderImage = data.founderImage.nodes[0].fluid;
  const [filtersToggle, setFiltersToggle] = useState<IFilterType>(offFilters);

  const [filters, setFilters] = useState<IAllItems>({
    sectors: [],
    stages: [],
    statuses: [],
    models: [],
  });

  const [filteredPortfolio, setFilteredPortfolio] = useState<Array<IPortfolio>>(
    []
  );
  const [initialPortfolios] = useState<Array<IPortfolio>>(
    portfolios.filter((x) => x.visibility !== "Involved With")
  );

  useEffect(() => {
    //on initial load fill in the filters and default filtered jobs
    setFilteredPortfolio(initialPortfolios);
    const sectors = initialPortfolios
      .filter((portfolio) => portfolio.sector)
      .map((portfolio) => ({
        value: portfolio.sector.name,
        isSelected: false,
      }))
      .filter(isUniqueFilterItems);
    const stages = initialPortfolios
      .map((portfolio) => ({
        value: portfolio.fundingStage,
        isSelected: false,
      }))
      .filter(isUniqueFilterItems);
    const statuses = initialPortfolios
      .map((portfolio) => ({
        value: portfolio.status,
        isSelected: false,
      }))
      .filter(isUniqueFilterItems);

    const models = initialPortfolios
      .map((portfolio) => ({
        value: portfolio.model,
        isSelected: false,
      }))
      .filter(isUniqueFilterItems);

    setFilters({ sectors, stages, statuses, models });
  }, [portfolios]);

  useEffect(() => {
    const selectedSectors = filters.sectors
      .filter((sec) => sec.isSelected)
      .map((sec) => sec.value);
    const selectedStages = filters.stages
      .filter((stage) => stage.isSelected)
      .map((stage) => stage.value);
    const selectedStatuses = filters.statuses
      .filter((status) => status.isSelected)
      .map((status) => status.value);
    const selectedModels = filters.models
      .filter((model) => model.isSelected)
      .map((model) => model.value);

    if (
      selectedSectors.length ||
      selectedStages.length ||
      selectedStatuses.length ||
      selectedModels.length
    ) {
      const tmpFilteredPortfolios = initialPortfolios.filter(
        (portfolio) =>
          portfolio.sector &&
          (selectedSectors.length
            ? selectedSectors.includes(portfolio.sector.name)
            : true) &&
          (selectedStages.length
            ? selectedStages.includes(portfolio.fundingStage)
            : true) &&
          (selectedStatuses.length
            ? selectedStatuses.includes(portfolio.status)
            : true) &&
          (selectedModels.length
            ? selectedModels.includes(portfolio.model)
            : true)
      );

      setFilteredPortfolio(tmpFilteredPortfolios);
    } else {
      setFilteredPortfolio(initialPortfolios);
    }
  }, [filters]);

  const toggleFilter = (filterObj: Partial<IFilterType>) => {
    setFiltersToggle({
      ...offFilters,
      ...filterObj,
    });
  };

  const filerItems = (filterItems: IFilterItem[], selectedItems: string[]) => {
    const selectedFilterItems: IFilterItem[] = filterItems.map((itm) =>
      selectedItems.includes(itm.value)
        ? { ...itm, isSelected: true }
        : { ...itm, isSelected: false }
    );
    return selectedFilterItems;
  };
  const updateFilters = (
    items: string[],
    filter: "sector" | "stage" | "status" | "model"
  ) => {
    switch (filter) {
      case "sector":
        const sectors: IFilterItem[] = filerItems(filters.sectors, items);
        setFilters({ ...filters, sectors });
        break;
      case "stage":
        const stages: IFilterItem[] = filerItems(filters.stages, items);
        setFilters({ ...filters, stages });

        break;
      case "status":
        const statuses: IFilterItem[] = filerItems(filters.statuses, items);
        setFilters({ ...filters, statuses });
        break;

      case "model":
        const models: IFilterItem[] = filerItems(filters.models, items);
        setFilters({ ...filters, models });
        break;
    }
  };

  const updateCommonFilters = (items: ISelectedItems) => {
    const sectors: IFilterItem[] = filerItems(filters.sectors, items.sectors);

    const stages: IFilterItem[] = filerItems(filters.stages, items.stages);

    const statuses: IFilterItem[] = filerItems(
      filters.statuses,
      items.statuses
    );
    const models: IFilterItem[] = filerItems(filters.models, items.models);

    setFilters({ sectors, stages, statuses, models });
  };

  return (
    <Layout>
      <SEO title="Portfolio" description="VF Rockstars" />
      <div className="py-8 md:py-14 w-full">
        <Img
          fluid={founderImage}
          alt="branding image"
          className="hidden md:block w-full h-auto object-cover mb-14"
        />
        <h1 className="text-4xl md:text-5xl font-bold mb-8 md:mb-12">
          Our Portfolio
        </h1>
        <div className=" mb-6 md:hidden">
          <Box
            onClick={() =>
              toggleFilter({
                commonFilter: !filtersToggle.commonFilter,
              })
            }
            text="Filters"
            icon="right"
            selected={filtersToggle.commonFilter}
            className="md:hidden"
            allFilterItems={filters}
            onCommonFilterApplySelection={(items) => {
              updateCommonFilters(items);
              toggleFilter({ commonFilter: false });
            }}
            closeBox={() => toggleFilter({ commonFilter: false })}
          />
        </div>
        <div className="hidden md:flex md:mb-14 md:justify-center">
          <Box
            onClick={() => toggleFilter({ sectors: !filtersToggle.sectors })}
            text="Sector"
            icon="down"
            selected={filtersToggle.sectors}
            className="border-r-0"
            specificFilterItems={filters.sectors}
            onApplySelection={(items) => {
              updateFilters(items, "sector");
              toggleFilter({ sectors: false });
            }}
            closeBox={() => toggleFilter({ sectors: false })}
          />{" "}
          <Box
            onClick={() => toggleFilter({ stages: !filtersToggle.stages })}
            text="Stage"
            icon="down"
            selected={filtersToggle.stages}
            className="border-r-0"
            specificFilterItems={filters.stages}
            onApplySelection={(items) => {
              updateFilters(items, "stage");
              toggleFilter({ stages: false });
            }}
            closeBox={() => toggleFilter({ stages: false })}
          />{" "}
          <Box
            onClick={() => toggleFilter({ statuses: !filtersToggle.statuses })}
            text="Status"
            icon="down"
            selected={filtersToggle.statuses}
            className="border-r-0"
            specificFilterItems={filters.statuses}
            onApplySelection={(items) => {
              updateFilters(items, "status");
              toggleFilter({ statuses: false });
            }}
            closeBox={() => toggleFilter({ statuses: false })}
          />
          <Box
            onClick={() => toggleFilter({ models: !filtersToggle.models })}
            text="Model"
            icon="down"
            selected={filtersToggle.models}
            specificFilterItems={filters.models}
            onApplySelection={(items) => {
              updateFilters(items, "model");
              toggleFilter({ models: false });
            }}
            closeBox={() => toggleFilter({ models: false })}
          />
        </div>
        <FilteredPortfolioDesktop
          filteredPortfolio={filteredPortfolio}
          className="mb-20"
        />
        <FilteredPortfolioMobile
          filteredPortfolio={filteredPortfolio}
          className="mb-12"
        />

        <h2 className="text-2xl font-bold mb-8">
          Startups we have been involved with
        </h2>
        <FilteredPortfolioDesktop
          filteredPortfolio={portfolios.filter(
            (x) => x.visibility === "Involved With" || x.visibility === "All"
          )}
        />
        <FilteredPortfolioMobile
          filteredPortfolio={portfolios.filter(
            (x) => x.visibility === "Involved With" || x.visibility === "All"
          )}
          className="mb-12"
        />
      </div>
    </Layout>
  );
};
export default PortfolioPage;

export const pageQuery = graphql`
  query getStartups {
    allStrapiStartups(
      filter: { published: { eq: true } }
      sort: { fields: portfolioPageSorting }
    ) {
      nodes {
        fundingStage
        websiteShort
        shortDescription

        headquarter
        exitDescription
        logo {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        longDescription
        name
        published
        sector {
          id
          name
        }
        slug
        techcrunch
        websiteFull
        status
        crunchbase
        facebook
        github
        instagram
        linkedin
        model
        portfolioPageSorting
        visibility
      }
    }
    founderImage: allImageSharp(
      filter: { fluid: { originalName: { eq: "people-on-portfolio.png" } } }
    ) {
      nodes {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
