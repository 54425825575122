import React from "react";

const InactiveLabel: React.FC<{ className?: string }> = ({
  className = "",
}) => (
  <div
    className={`font-bold md:text-xs lg:text-base text-gray border-2 border-gray flex items-center ${className}`}
  >
    INACTIVE
  </div>
);
export default InactiveLabel;
