import { useState, useEffect } from "react";
import { IFilterItem } from "../models/jobs";

const useFilterItems = (rowItems) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  useEffect(() => {
    const tmpSelectedItems = rowItems
      .filter((item) => item.isSelected)
      .map((item) => item.value);
    setSelectedItems(tmpSelectedItems);
  }, [rowItems]);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: IFilterItem
  ) => {
    const index = selectedItems.findIndex((itm) => itm === item.value);
    if (index >= 0) {
      const tmpSelectedItems = [...selectedItems];
      tmpSelectedItems.splice(index, 1);
      setSelectedItems(tmpSelectedItems);
    } else {
      setSelectedItems([...selectedItems, item.value]);
    }
  };

  const clearSelection = () => {
    setSelectedItems([]);
  };

  return [selectedItems, onChange, clearSelection] as const;
};

export default useFilterItems;
