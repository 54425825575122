import React, { useState } from "react";
import { IAllItems, ISelectedItems } from "../../models/portfolio";
import Button from "../button";
import useFilterItems from "../../hooks/use-filter-items";
import FilterItemsList from "../filter-items-list";
const close = require("../../images/close.svg");

const CommonFilter: React.FC<{
  items: IAllItems;
  onApplySelection: (items: ISelectedItems) => void;
  onClose?: () => void;
}> = ({ items, onApplySelection, onClose }) => {
  const [selectedSectors, onSectorsChange, clearSectors] = useFilterItems(
    items.sectors
  );
  const [selectedStages, onStagesChange, clearStages] = useFilterItems(
    items.stages
  );
  const [selectedStatuses, onStatusesChange, clearStatuses] = useFilterItems(
    items.statuses
  );
  const [selectedModels, onModelsChange, clearModels] = useFilterItems(
    items.models
  );

  return (
    <div className="fixed inset-0  bg-white z-50 md:hidden max-h-screen">
      <div
        className="w-full h-14 flex justify-between items-center py-3 px-4
              border-b border-dropDownBorder border-solid"
      >
        <h2 className="text-2xl">Filters</h2>
        <img
          src={close}
          alt="close"
          className="cursor-pointer"
          onClick={onClose}
        />
      </div>
      <div className="p-4 flex flex-col flex-no-wrap h-commonFilter overflow-y-scroll ">
        <h4 className="font-bold"> Sector</h4>
        <FilterItemsList
          items={items.sectors}
          selectedItems={selectedSectors}
          onChange={onSectorsChange}
        />
        <h4 className="font-bold"> Stage</h4>
        <FilterItemsList
          items={items.stages}
          selectedItems={selectedStages}
          onChange={onStagesChange}
        />
        <h4 className="font-bold"> Status </h4>
        <FilterItemsList
          items={items.statuses}
          selectedItems={selectedStatuses}
          onChange={onStatusesChange}
        />

        <h4 className="font-bold"> Model </h4>
        <FilterItemsList
          items={items.models}
          selectedItems={selectedModels}
          onChange={onModelsChange}
        />
      </div>
      <div className="absolute bottom-0 flex h-16 w-full items-center justify-around">
        <Button
          secondary={true}
          onClick={() => {
            clearSectors();
            clearStages();
            clearStatuses();
            clearModels();
          }}
          className="border-primary"
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            onApplySelection({
              sectors: selectedSectors,
              stages: selectedStages,
              statuses: selectedStatuses,
              models: selectedModels,
            });
            onClose();
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default CommonFilter;
